const FighterSlide = ({ fighter, category, noFighter }) => {
	if (noFighter) {
		return (
			<div className="fighter-slide">
				<div
					className="fighter-image"
					style={{
						backgroundImage: 'url("/images/silhouette.png")',
					}}></div>
				<div className="fighter-banner-container">
					<div className="breakable-background-text">
						<span className="fighter-name">Select a fighter</span>
					</div>
					<span className="banner-track primary medium">
						{category}
					</span>
				</div>
			</div>
		);
	}
	return (
		<div className="fighter-slide">
			<div
				className="fighter-image"
				style={{
					backgroundImage: 'url("/images/fighter-2.webp")',
				}}></div>
			<div className="fighter-banner-container">
				<div className="breakable-background-text">
					<span className="fighter-name">
						{fighter.name.split(" ")[0]}
					</span>
					<span className="fighter-name">
						{fighter.name.split(" ")[1]}
					</span>
				</div>
				<span className="banner-track primary medium">
					{fighter.division}
				</span>
			</div>
		</div>
	);
};

export default FighterSlide;

import Countdown from "react-countdown";
import moment from "moment";
import MainCTA from "../form/MainCTA";

const FightCard = ({ fight }) => {
	const now = new Date();
	const date = moment().utc().format(fight.fightDate, "DD/MM/YYYY HH:mm", true);
	const fightDate = new Date(date)
	const isPastFight = fightDate.getTime() < now.getTime();

	const calculateOdds = (redOdds, blueOdds) => {
		const americanToDecimal = (odds) => {
			if (odds < 0) {
				return (100 / Math.abs(odds)) + 1;
			} else {
				return (odds / 100) + 1;
			}
		}

		const redDecimal = americanToDecimal(redOdds);
		const blueDecimal = americanToDecimal(blueOdds);

		return `${redDecimal.toFixed(2)}:${blueDecimal.toFixed(2)}`
	}

	return (
		<div className={`fight-card ${isPastFight ? "past" : ""}`} >
			<div className="flex-distributed-row m-b-s">
				<span className="banner-track primary medium" >Red<span className="desktop-and-tablet-content"> corner</span></span>
				<span className="banner-track blue medium" >Blue<span className="desktop-and-tablet-content"> corner</span></span>
			</div>

			<div className="flex-center">
				<div className="fighter-fight-image left" style={{ backgroundImage: 'url("/images/fighter-3.webp")' }}></div>
				<div className="fighter-fight-image right" style={{ backgroundImage: 'url("/images/fighter-1.webp")' }}></div>
			</div>

			<div className="fight-details flex-centered-column m-b-xs" >
				<div className="breakable-background-text">
					<span className="banner-track black large" >{fight.redCornerName}</span>
					<span className="banner-track black large" > vs </span>
					<span className="banner-track black large" >{fight.blueCornerName}</span>
				</div>

				{!isPastFight && <span className="banner-track primary medium" >
					<span className="desktop-and-tablet-content">COUNTDOWN: </span><Countdown date={fightDate} />
				</span>}
				{isPastFight && <span className="banner-track primary medium" >{moment(fightDate).format("MMM Do YYYY, h:mm a")}</span>}
			</div>

			<div className="flex-distributed-row">
				<MainCTA disabled="true" className="primary" >{fight.redCornerName}</MainCTA>

				{!isPastFight && <span className="banner-track gray small m-h-xs" ><span className="desktop-and-tablet-content" >Odds</span> {calculateOdds(fight.redOdds, fight.blueOdds)}</span>}

				<MainCTA disabled="true" className="blue" >{fight.blueCornerName}</MainCTA>
			</div>
		</div>
	);
};

export default FightCard;
import DonutStat from "./DonutStat";

const calculateAccuracy = (successful, attempted) => {
	if (!attempted || attempted === 0) return 0;
	return Math.round((successful / attempted) * 100);
  };

const FighterSubcontent = ({ fighter }) => {
	if (!fighter) {
		return null;
	}

	const strikingAccuracy = calculateAccuracy(
		fighter.strikes?.successful || 0,
		fighter.strikes?.attempted || 0
	  );
	
	  const takedownAccuracy = calculateAccuracy(
		fighter.takedowns?.successful || 0,
		fighter.takedowns?.attempted || 0
	  );

	return (
		<div className="fighter-subcontent">
			<div
				className="fighter-subcontent-image"
				style={{ backgroundImage: 'url("/images/fighter-2.webp")' }}
			></div>

			<div className="fighter-stats">
				<h2 className="m-t-s m-b-xs">{fighter.name}</h2>
				<h5>{fighter.division}</h5>
				<h5 className="m-b-xs">{fighter.record || "N/A"}</h5>

				<div className="fighter-stat-container">
					<span className="stat-value">{fighter.wins?.ko || 0}</span>
					<span className="stat-name">Wins by knockout</span>
				</div>
				<div className="fighter-stat-container">
					<span className="stat-value">{fighter.wins?.sub || 0}</span>
					<span className="stat-name">Wins by submission</span>
				</div>
				<div className="fighter-stat-container">
					<span className="stat-value">{fighter.wins?.dec || 0}</span>
					<span className="stat-name">Wins by decision</span>
				</div>

				<div className="flex-row">
					<DonutStat
						percentage={strikingAccuracy}
						statName="Striking accuracy"
					/>
					<DonutStat
						percentage={takedownAccuracy}
						statName="Takedown accuracy"
					/>
				</div>
			</div>
		</div>
	);
};

export default FighterSubcontent;
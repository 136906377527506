import Avatar from "boring-avatars";

import ContentLimiter from "../components/ContentLimiter";
import MainCTA from "../components/form/MainCTA";
import Modal from "../components/Modal.js";
import BuyTokensForm from "../components/form/forms/BuyTokensForm.js";
import PersonalInfoForm from "../components/form/forms/PersonalInfoForm.js";

import config from "../config";

import { useEffect } from "react";
import {
	useUserStore,
	useModalStore,
	openPrompt,
} from "../store/globalStore.js";

const Profile = () => {
	const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);
	const userInfo = useUserStore((state) => state.userInfo);

	const tokenModalOpen = useModalStore((state) => state.tokenModalOpen);
	const setTokenModalOpen = useModalStore((state) => state.setTokenModalOpen);

	const deleteAccount = useUserStore((state) => state.deleteAccount);

	useEffect(() => {
		fetchUserInfo();
	}, []);

	if (!userInfo) return <div></div>;

	return (
		<ContentLimiter>
			<div className="col-4 col-m-12 m-t-m">
				<div className="flex-centered-column">
					<div className="avatar-container m-b-xs">
						<Avatar
							size={"160%"}
							name={userInfo.username}
							variant="pixel"
							colors={[
								config.COLORS.RED,
								config.COLORS.GRAY,
								config.COLORS.WHITE,
							]}
						/>
					</div>

					<span className="banner-track black medium">
						{userInfo.username}
					</span>
					{/* <span className="banner-track primary small">
						Joined {timeAgo.format(Date.now() - 2 * 60 * 60 * 1000)}
					</span> */}
				</div>
			</div>
			<div className="col-8 col-m-12 m-t-m">
				<div className="season-details p-b-m m-b-m bottom-border">
					<div>
						<span className="profile-season-banner banner-track black large">
							Season 200 member
						</span>
					</div>
					<div>
						<span className="banner-track primary medium">
							Currently 186th place
						</span>
					</div>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<h3 className="m-b-s">Buy coins</h3>

					<p className="m-b-s">
						In order to buy coins, click on the button bellow:
					</p>

					{tokenModalOpen ? (
						<Modal
							title="Buy tokens"
							onClose={() => setTokenModalOpen(false)}>
							<BuyTokensForm user={userInfo} />
						</Modal>
					) : null}

					<MainCTA onClick={() => setTokenModalOpen(true)}>
						Buy coins
					</MainCTA>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<PersonalInfoForm user={userInfo} />
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<h3 className="m-b-s">Change password</h3>

					<p className="m-b-s">
						In order to change your password, click on the button
						bellow:
					</p>

					<MainCTA
						className="m-t-s"
						onClick={() =>
							window.open(`/submit-new-password`, "_blank")
						}>
						Change password
					</MainCTA>
				</div>

				<div className="p-b-m">
					<h3 className="m-b-s">Delete account</h3>

					<p className="m-b-s">
						If you want to delete your account, click on the button
						bellow:
					</p>

					<MainCTA
						className="red m-t-s"
						onClick={async () => {
							openPrompt({
								title: "Are you sure?",
								message:
									"Are you sure that you want to delete your account? All your data, including your ratings will be lost!",
								onConfirm: async () => await deleteAccount(),
								confirmButtonText: "I'm sure",
								confirmButtonClassName: "red",
								cancelButtonClassName: "red",
							});
						}}>
						Delete account
					</MainCTA>
				</div>
			</div>
		</ContentLimiter>
	);
};

export default Profile;

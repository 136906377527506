import PasswordField from "../../components/form/PasswordField";
import MainCTA from "../../components/form/MainCTA";
import { useUserStore } from "../../store/globalStore";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { warn } from "../../utils/toast";
import AuthBackgroundArt from "../../components/AuthBackgroundArt.js";

const SubmitNewPassword = () => {
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const submitNewPassword = useUserStore((state) => state.submitNewPassword);

	const { token } = useParams();
	const navigate = useNavigate();

	const submit = async () => {
		if (password !== repeatPassword) {
			warn("Passwords do not match!");
			return;
		}
		try {
			await submitNewPassword({ password, token });
			navigate("/account/reset/finish");
		} catch (err) {
			navigate("/account/error");
		}
	};

	return (
		<div className="auth-page flex-center">
			<AuthBackgroundArt />

			<div className="auth-form flex-column">
				<h2 className="m-b-s">Reset password</h2>

				<div className="m-b-xs">
					<PasswordField
						label="New password"
						placeholder="e.g. *******"
						onChange={(fieldValue) => setPassword(fieldValue)}
					/>
				</div>

				<div className="m-b-xs">
					<PasswordField
						label="Repeat it"
						placeholder="e.g. *******"
						onChange={(fieldValue) => setRepeatPassword(fieldValue)}
					/>
				</div>

				<MainCTA
					onClick={submit}
					className="primary m-t-s">
					Submit
				</MainCTA>
			</div>
		</div>
	);
};

export default SubmitNewPassword;

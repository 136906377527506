const AbstractBackgroundArt = () => {
	return (
		<div className="abstract-art-container">
			<img
				className="left"
				src="/images/abstract-1.png"
			/>

			<img
				className="right"
				src="/images/abstract-2.png"
			/>
		</div>
	);
};

export default AbstractBackgroundArt;

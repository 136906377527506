import axios from "axios";
import { create } from "zustand";
import config from "../config.js";
import toast from "../utils/toast.js";

axios.defaults.baseURL = config.API_URL;
axios.interceptors.response.use(undefined, (error) => {
	const errorMessage =
		error.response?.data?.message || error.response?.data || error.message;

	toast.error(
		typeof errorMessage === "string" ? errorMessage : "An error occurred"
	);

	return Promise.reject(error);
});

export const signOut = () => {
	localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);

	window.location.reload();
};

export const useUserStore = create((set, get) => ({
	userInfo: null,
	userLineup: [],
	setUserInfo: (userInfo) => set({ userInfo }),
	fetchUserInfo: async () => {
		const { data } = await axios.get(`/user/current`);

		set({ userInfo: data.user });
	},
	fetchUserLineup: async () => {
		const { data } = await axios.get(`/fighter/lineup`);

		set({ userLineup: data.lineup });
	},

	login: async (data) => {
		const response = await axios.post(`/authentication/sign-in`, data);

		localStorage.setItem(
			config.LOCAL_STORAGE_TOKEN_KEY,
			response.data.accessToken
		);

		axios.defaults.headers.common[
			"Authorization"
		] = `Bearer ${response.data.accessToken}`;
	},
	register: async (data) => {
		await axios.post(`/authentication`, data);
	},
	passwordReset: async (data) => {
		await axios.post(`/authentication/forgot-password`, data);
	},
	submitNewPassword: async (data) => {
		await axios.post(`/authentication/reset-password`, data);
	},
	updateUsername: async (data) => {
		await axios.patch(`/user/${data.id}`, {
			username: data.username,
		});
	},

	deleteAccount: async () => {
		await axios.delete(`/account`);

		toast.info("Account deleted! Signing out...");

		setTimeout(() => signOut(), 3000);
	},
}));

export const useFighterStore = create((set) => ({
	rankings: {},
	selectedCategory: "Flyweight Division",
	setSelectedCategory: (category) => set({ selectedCategory: category }),

	fetchRankings: async () => {
		const { data } = await axios.get(`/ranking/full-info`);
		set({ rankings: data.rankings });
	},
}));

export const useModalStore = create((set, get) => ({
	tokenModalOpen: false,
	prompt: null,
	setTokenModalOpen: (isTokenModalOpen) =>
		set(() => ({
			tokenModalOpen: isTokenModalOpen,
		})),
	openPrompt: (prompt) =>
		set(() => ({
			prompt,
		})),
	closePrompt: () =>
		set(() => ({
			prompt: null,
		})),
}));

export const useLeaderboardStore = create((set) => ({
	leaderboard: [],
	totalPages: 1,
	currentPage: 0,

	fetchCurrentUserLeaderboard: async (limit = 10) => {
		const response = await axios.get(`/leaderboard/current-user`, {
			headers: {
				"X-Limit": limit,
			},
		});

		set({
			leaderboard: response.data.leaderboard.map((user, index) => ({
				rank: index + 1,
				username: user.username,
				points: user.points,
				balance: user.currentBalance,
			})),
			totalPages: parseInt(response.headers["x-total-pages"], 10),
			currentPage: 0,
		});
	},

	fetchLeaderboard: async (page = 1, limit = 10) => {
		const response = await axios.get(`/leaderboard`, {
			headers: {
				"X-Limit": limit,
				"X-Page": page,
			},
		});

		set({
			leaderboard: response.data.leaderboard.map((user, index) => ({
				rank: (page - 1) * limit + index + 1,
				username: user.username,
				points: user.points,
				balance: user.currentBalance,
			})),
			totalPages: parseInt(response.headers["x-total-pages"], 10),
			currentPage: page - 1,
		});
	},
}));

export const useFightsStore = create((set) => ({
	myLineupFights: [],
	allFights: [],

	fetchMyLineupFights: async () => {
		const response = await axios.get(`/event/upcoming-for-user`);
		set({ myLineupFights: response.data.upcomingFights });
	},
	fetchAllFights: async () => {
		const response = await axios.get(`/event/upcoming`);
		set({ allFights: response.data.upcomingFights });
	},
}));

// Export as a separate const for easier use in components.
export const openPrompt = useModalStore.getState().openPrompt;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const Logout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);
		navigate('/login');
	}, [navigate]);

	return <div>Logging out...</div>
};

export default Logout;
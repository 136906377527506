import ReactPaginate from "react-paginate";
import { useEffect } from "react";

import MainCTA from "../components/form/MainCTA";
import ContentLimiter from "../components/ContentLimiter";
import DataTable from "../components/DataTable";

import { useLeaderboardStore } from "../store/globalStore";
import { openPrompt } from "../store/globalStore.js";

const Leaderboard = () => {
	const {
		leaderboard,
		totalPages,
		currentPage,
		fetchCurrentUserLeaderboard,
		fetchLeaderboard,
	} = useLeaderboardStore();

	const limit = 10;

	useEffect(() => {
		fetchCurrentUserLeaderboard(limit);
	}, [fetchCurrentUserLeaderboard, limit]);

	const handlePageChange = (selectedPage) => {
		const page = selectedPage.selected + 1;
		fetchLeaderboard(page, limit);
	};

	return (
		<ContentLimiter>
			<div className="m-t-l m-b-l">
				{/* Still not joined */}
				<div className="p-b-m m-b-m bottom-border">
					<h1 className="m-b-m">
						WIN A <span className="primary-color-text">REWARD</span>
					</h1>

					<h5 className="m-b-s">
						Join the current season, be in top 10% of the players
						and win a{" "}
						<span className="primary-color-text">FIGHT PASS</span>!
					</h5>

					{/* Not enough coins */}
					{/* <div>
						<p>
							You currently don't have enough coins in your wallet
							(<u>500 000</u> coins is required). You can go to
							your profile and buy more.
						</p>

						<MainCTA
							className="primary m-t-s"
							href="/profile">
							Buy coins!
						</MainCTA>
					</div> */}

					{/* Enough coins */}
					<div>
						<p>
							Price for joining the season is 500 000 coins. By
							clicking the button bellow, you will participate in
							the global leaderboard and possibly win a{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://welcome.ufcfightpass.com/region/row">
								Fight pass
							</a>
							.
						</p>

						<MainCTA
							className="primary m-t-s"
							onClick={async () => {
								openPrompt({
									title: "Are you sure?",
									message:
										"Are you sure that you want to join the current season? 500 000 coins will be taken from your account!",
									onConfirm: async () =>
										console.log("confirmed"),
									confirmButtonText: "I'm sure",
									confirmButtonClassName: "red",
									cancelButtonClassName: "red",
								});
							}}>
							Join!
						</MainCTA>
					</div>
				</div>

				{/* Already joined */}
				{/* <div className="p-b-m m-b-m bottom-border">
					<h1 className="m-b-m">
						CURRENTLY RANKED{" "}
						<span className="primary-color-text">#186</span>
					</h1>

					<h5 className="m-b-s">You are a member of season #24</h5>

					<p>
						The season ends in 118 days! Stay in the top 10% of
						players and win a{" "}
						<span className="primary-color-text">FIGHT PASS</span>!
					</p>

					<MainCTA
						className="primary m-t-s"
						href="https://welcome.ufcfightpass.com/region/row">
						Learn more
					</MainCTA>
				</div> */}
			</div>

			<div>
				<span className="banner-track primary medium">
					Global leaderboard
				</span>
			</div>

			<div className="m-v-m">
				<DataTable
					fields={{
						rank: {
							text: "Rank",
							className: "col-1",
							renderer: (item) => {
								if (item.rank === 1)
									return (
										<span className="rank gold">
											{item.rank}
										</span>
									);
								if (item.rank === 2)
									return (
										<span className="rank silver">
											{item.rank}
										</span>
									);
								if (item.rank === 3)
									return (
										<span className="rank bronze">
											{item.rank}
										</span>
									);

								return (
									<span className="rank">{item.rank}</span>
								);
							},
						},
						username: {
							text: "Username",
							className: "col-6",
							renderer: (item) => (
								<a href={`/profile/${item.username}`}>
									{item.username}
								</a>
							),
						},
						points: {
							text: "Points",
							className: "col-2",
							renderer: (item) => (
								<span>
									{item.points}
									<span className="primary-color-text">
										{" "}
										▲
									</span>
								</span>
							),
						},
						balance: {
							text: "Balance",
							className: "col-3",
							renderer: (item) => <span>{item.balance} $</span>,
						},
					}}
					data={leaderboard}
				/>

				<div className="pagination-container">
					<ReactPaginate
						initialPage={1}
						activeClassName={"item active "}
						breakClassName={"item break-me "}
						breakLabel={"..."}
						containerClassName={"pagination"}
						disabledClassName={"disabled-page"}
						marginPagesDisplayed={2}
						nextClassName={"item next "}
						nextLabel={
							<img
								src="/images/icons/angle-right-white.svg"
								alt=""
							/>
						}
						onPageChange={handlePageChange}
						pageCount={totalPages}
						pageClassName={"item pagination-page "}
						pageRangeDisplayed={2}
						previousClassName={"item previous"}
						previousLabel={
							<img
								style={{ transform: "rotateZ(180deg)" }}
								src="/images/icons/angle-right-white.svg"
								alt=""
							/>
						}
						forcePage={currentPage}
					/>
				</div>
			</div>
		</ContentLimiter>
	);
};

export default Leaderboard;

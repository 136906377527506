const AuthBackgroundArt = () => {
	return (
		<div className="auth-art-container">
			<img
				className="auth-background-image"
				src="/images/fighter-2.webp"
			/>

			<img
				className="auth-logo"
				src="/images/logo-red.svg"
			/>
		</div>
	);
};

export default AuthBackgroundArt;

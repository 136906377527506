import Modal from "./Modal";
import MainCTA from "./form/MainCTA";
import { useModalStore } from "../store/globalStore";

const Prompt = () => {
	const prompt = useModalStore((state) => state.prompt);
	const closePrompt = useModalStore((state) => state.closePrompt);

	const cancel = async () => {
		if (prompt.onCancel) await prompt.onCancel();

		closePrompt();
	};

	const confirm = async () => {
		if (prompt.onConfirm) await prompt.onConfirm();

		closePrompt();
	};

	if (!prompt) return null;

	return (
		<Modal
			title={prompt.title}
			className="prompt"
			onClose={cancel}>
			<p className="m-b-s">{prompt.message}</p>

			<div className="flex-row m-v-xs">
				<MainCTA
					className={`primary m-r-xs ${
						prompt.confirmButtonClassName
							? prompt.confirmButtonClassName
							: ""
					}`}
					onClick={confirm}>
					{prompt.confirmButtonText
						? prompt.confirmButtonText
						: "Confirm"}
				</MainCTA>

				<MainCTA
					className={`primary negative ${
						prompt.cancelButtonClassName
							? prompt.cancelButtonClassName
							: ""
					}`}
					onClick={cancel}>
					{prompt.cancelButtonText
						? prompt.cancelButtonText
						: "Cancel"}
				</MainCTA>
			</div>
		</Modal>
	);
};

export default Prompt;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCTA from "../../components/form/MainCTA";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import { useUserStore } from "../../store/globalStore.js";
import { error, success } from "../../utils/toast.js";
import AuthBackgroundArt from "../../components/AuthBackgroundArt.js";

const Register = () => {
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [couponCode, setCouponCode] = useState("");
	const navigate = useNavigate();
	const register = useUserStore((state) => state.register);

	const handleRegisterClick = async () => {
		try {
			await register({ email, password, username });
			navigate("/");
			success("Account successfully created");
		} catch (err) {}
	};

	return (
		<div className="auth-page flex-center">
			<AuthBackgroundArt />

			<div className="auth-form flex-column">
				<h2 className="m-b-s">Create an account</h2>

				<div className="m-b-xs">
					<TextField
						label="E-mail"
						placeholder="e.g. name@domain.com"
						onChange={(fieldValue) => setEmail(fieldValue)}
					/>
				</div>

				<div className="m-b-xs">
					<TextField
						label="Username"
						placeholder="e.g. hadrbari024"
						onChange={(fieldValue) => setUsername(fieldValue)}
					/>
				</div>

				<div className="m-b-xs">
					<PasswordField
						label="Password"
						placeholder="e.g. *******"
						onChange={(fieldValue) => setPassword(fieldValue)}
					/>
				</div>

				<div className="m-b-xs">
					<TextField
						label="Coupon code"
						placeholder="If you are refered by someone"
						onChange={(fieldValue) => setCouponCode(fieldValue)}
					/>
				</div>

				<span className="disclaimer">
					If you already have an account, log in{" "}
					<a href="/login">here</a>.
				</span>

				<MainCTA
					onClick={handleRegisterClick}
					className="primary m-t-s">
					Register
				</MainCTA>
			</div>
		</div>
	);
};

export default Register;

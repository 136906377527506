import ContentLimiter from "../components/ContentLimiter";
import MainCTA from "../components/form/MainCTA";
import FightCard from '../components/fight/FightCard';
import { useFightsStore } from "../store/globalStore";
import { useEffect, useState } from "react";

const Fights = () => {
	const {
		myLineupFights,
		allFights,
		fetchMyLineupFights,
		fetchAllFights
	} = useFightsStore();

	useEffect(() => {
		fetchMyLineupFights();
		fetchAllFights()
	}, []);

	const [showAllFights, setShowAllFights] = useState(false);

	return (
		<ContentLimiter>
			<div className="m-t-l m-b-l">
				<h1 className="m-b-m"><span className="primary-color-text">{allFights.length}</span> FIGHTS SO FAR</h1>
				<h5 className="m-b-s">Check out the following and past fights, either for all fighters or your lineup only.</h5>

				<p>You can check all the fights or for your lineup only.</p>
			</div>

			<div className="multi-btn" >
				<MainCTA className="primary m-r-xs" onClick={() => setShowAllFights(false)}>My lineup</MainCTA>
				<MainCTA onClick={() => setShowAllFights(true)}>All fights</MainCTA>
			</div>

			<div className="fights-container m-v-m" >
				{(showAllFights ? allFights : myLineupFights).length > 0 ? (
					(showAllFights ? allFights : myLineupFights).map((fight, index) => (
						<FightCard key={index} fight={fight} />
					))
				) : (
					<span className="centered-text">No more fights to show.</span>
				)}
			</div>
		</ContentLimiter>
	);
};

export default Fights;
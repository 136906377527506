import { Navigate } from "react-router-dom";
import config from "./config";

const ProtectedRoute = ({ children }) => {
	if (!localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY))
		return (
			<Navigate
				to="/login"
				replace
			/>
		);

	return children;
};

export default ProtectedRoute;
import Avatar from "boring-avatars";

import ContentLimiter from "../components/ContentLimiter";
import TextField from "../components/form/TextField";

import config from "../config";
import timeAgo from "../utils/timeAgo";

const PublicProfile = () => {
	return (
		<ContentLimiter>
			<div className="col-4 col-m-12 m-t-m">
				<div className="flex-centered-column">
					<div className="avatar-container m-b-xs">
						<Avatar
							size={"160%"}
							name="kockarevicivan"
							variant="pixel"
							colors={[
								config.COLORS.RED,
								config.COLORS.GRAY,
								config.COLORS.WHITE,
							]}
						/>
					</div>

					<span className="banner-track black medium">
						kockarevicivan
					</span>
					<span className="banner-track primary small">
						Joined {timeAgo.format(Date.now() - 2 * 60 * 60 * 1000)}
					</span>
				</div>
			</div>
			<div className="col-8 col-m-12 m-t-m">
				<div className="season-details p-b-m m-b-m bottom-border">
					<div>
						<span className="profile-season-banner banner-track black large">
							Season 200 member
						</span>
					</div>
					<div>
						<span className="banner-track primary medium">
							Currently 186th place
						</span>
					</div>
				</div>

				<div className="p-b-m m-b-m">
					<h3 className="m-b-s">Profile information</h3>

					<div className="m-b-xs">
						<TextField
							label="E-mail"
							placeholder="e.g. name@domain.com"
							value="kockarevic.ivan@gmail.com"
							readOnly
						/>
					</div>

					<div className="m-b-xs">
						<TextField
							label="Username"
							placeholder="e.g. name2024"
							value="kockarevicivan"
							readOnly
						/>
					</div>

					<div className="m-b-xs">
						<TextField
							label="Balance"
							placeholder="e.g. 200.000,00$"
							value="132.000 $"
							readOnly
						/>
					</div>
				</div>
			</div>
		</ContentLimiter>
	);
};

export default PublicProfile;

import Slider from "react-slick";

import ContentLimiter from "../components/ContentLimiter";
import FighterSlide from "../components/fighter/FighterSlide";
import DataTable from "../components/DataTable";
import MainCTA from "../components/form/MainCTA";
import FighterSubcontent from "../components/fighter/FighterSubcontent";

import { useEffect } from "react";
import {
	openPrompt,
	useUserStore,
	useFighterStore,
} from "../store/globalStore.js";

const categoryMap = {
	"Flyweight Division": "flyweightMen",
	"Bantamweight Division": "bantamweightMen",
	"Featherweight Division": "featherweightMen",
	"Lightweight Division": "lightweightMen",
	"Welterweight Division": "welterweightMen",
	"Middleweight Division": "middleweightMen",
	"Heavyweight Division": "heavyweightMen",
	"Women's Strawweight Division": "strawweightWomen",
	"Women's Flyweight Division": "flyweightWomen",
	"Women's Bantamweight Division": "bantamweightWomen",
};

const Lineup = () => {
	const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);
	const userInfo = useUserStore((state) => state.userInfo);
	const fetchUserLineup = useUserStore((state) => state.fetchUserLineup);
	const userLineup = useUserStore((state) => state.userLineup);

	const fetchRankings = useFighterStore((state) => state.fetchRankings);
	const rankings = useFighterStore((state) => state.rankings);
	const selectedCategory = useFighterStore((state) => state.selectedCategory);
	const setSelectedCategory = useFighterStore(
		(state) => state.setSelectedCategory
	);

	useEffect(() => {
		fetchUserInfo();
		fetchUserLineup();
		fetchRankings();
		if (!selectedCategory) {
			setSelectedCategory(categories[0]);
		}
	}, [
		fetchUserInfo,
		fetchUserLineup,
		fetchRankings,
		selectedCategory,
		setSelectedCategory,
	]);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (index) => {
			setSelectedCategory(Object.keys(categoryMap)[index]);
		},
	};

	const categories = [
		"Flyweight Division",
		"Bantamweight Division",
		"Featherweight Division",
		"Lightweight Division",
		"Welterweight Division",
		"Middleweight Division",
		"Heavyweight Division",
		"Women's Strawweight Division",
		"Women's Flyweight Division",
		"Women's Bantamweight Division",
	];

	const lineupByCategory = Object.keys(categoryMap).map((category) => {
		const fighter = userLineup.find(
			(fighter) => fighter.division === category
		);
		return { category, fighter };
	});

	const backendKey = categoryMap[selectedCategory] || null;
	const rankingsForCategory = backendKey ? rankings[backendKey] || [] : [];

	const currentFighterInSlider = lineupByCategory.find(
		(lineup) => lineup.category === selectedCategory
	)?.fighter;

	const initialSelectedRank =
		rankingsForCategory.findIndex(
			(fighter) =>
				currentFighterInSlider &&
				currentFighterInSlider._id === fighter._id
		) + 1;

	return (
		<ContentLimiter>
			<div className="m-t-s">
				<div>
					<span className="banner-track black large">
						Your lineup
					</span>
				</div>
				<div>
					<span className="banner-track primary medium">
						Slide to change division
					</span>
				</div>
			</div>

			<div className="p-h-s">
				<Slider {...settings}>
					{lineupByCategory.map(({ category, fighter }, index) => (
						<FighterSlide
							key={index}
							fighter={fighter}
							category={category}
							noFighter={!fighter}
						/>
					))}
				</Slider>
			</div>

			<div className="m-v-m">
				<DataTable
					fields={{
						rank: {
							text: "Rank",
							className: "col-1",
						},
						name: {
							text: "Name",
							className: "col-5",
						},
						price: {
							text: "Price",
							className: "col-4",
						},
						actions: {
							text: "",
							className: "col-2",
							renderer: (item) => (
								<MainCTA
									className="small negative m-r-xxs"
									onClick={async () => {
										openPrompt({
											title: "Are you sure?",
											message: `Are you sure that you want to buy ${item.name} for ${item.price} coins?`,
											onConfirm: async () =>
												console.log("Fighter bought."),
											confirmButtonText: "I'm sure",
											confirmButtonClassName: "red",
											cancelButtonClassName: "red",
										});
									}}>
									Buy
								</MainCTA>
							),
						},
					}}
					data={rankingsForCategory.map((fighter, index) => ({
						rank: index + 1,
						name: fighter.name,
						price: `${fighter.price}$`,
						fighter,
					}))}
					subcontentRenderer={(item) => (
						<FighterSubcontent fighter={item.fighter} />
					)}
					initialSelectedRank={initialSelectedRank}
				/>
			</div>
		</ContentLimiter>
	);
};

export default Lineup;

import { useState } from "react";
import TextField from "../TextField.js";
import CheckboxField from "../CheckboxField.js";
import TextareaField from "../TextareaField.js";
import MainCTA from "../MainCTA.js";
import ValidatedForm from "../../../utils/validation.js";
import { openPrompt, useUserStore } from "../../../store/globalStore.js";
import { success, error } from "../../../utils/toast.js";

const PersonalInfoForm = ({ user, onSubmit = () => null }) => {
	const [username, setUsername] = useState(user.username);
	const { updateUsername } = useUserStore();

	const handleUpdateUsername = async () => {
		try {
			await updateUsername({ id: user._id, username });
			success("Username updated successfully");
		} catch {
			setUsername(user.username);
		}
	};

	// const form = new ValidatedForm();

	// form.useState(
	// 	...useState({
	// 		email: {
	// 			value: user.email,
	// 			validators: [],
	// 		},
	// 		name: {
	// 			value: user.username,
	// 			validators: [],
	// 		},
	// 	})
	// );

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3 className="m-b-s">Change username</h3>

			{/* <div className="m-b-xs">
				<TextField
					label="E-mail"
					placeholder="e.g. name@domain.com"
					mandatory
					value={form.state.email.value}
					errorMessage={form.state.email.errorMessage}
					onChange={form.set("email")}
				/>
			</div> */}

			<div className="m-b-s">
				<TextField
					label="Username"
					placeholder="e.g. SuperCool"
					value={username}
					errorMessage=""
					mandatory
					onChange={(value) => {
						setUsername(value);
					}}
				/>
			</div>
			<div className="m-b-xs">
				<MainCTA onClick={handleUpdateUsername}>Save</MainCTA>
			</div>

			{/* <MainCTA
				className="m-t-xs"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to update your personal information?",
						onConfirm: async () =>
							await onSubmit({
								email: form.state.email.value,
								name: form.state.name.value,
								description: form.state.description.value,
								calendarLink: form.state.calendarLink.value,
							}),
					});
				}}>
				Save changes
			</MainCTA> */}
		</form>
	);
};

export default PersonalInfoForm;

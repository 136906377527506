import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import MainCTA from "../../components/form/MainCTA";
import PasswordField from "../../components/form/PasswordField";
import TextField from "../../components/form/TextField";
import config from "../../config.js";
import { useUserStore } from "../../store/globalStore.js";
import { success } from "../../utils/toast.js";
import AuthBackgroundArt from "../../components/AuthBackgroundArt.js";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const login = useUserStore((state) => state.login);

	if (localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY)) {
		return (
			<Navigate
				to="/lineup"
				replace
			/>
		);
	}

	const handleLoginClick = async () => {
		try {
			await login({ email, password });

			navigate("/lineup");
			success("Successfully logged in");
		} catch (err) {
			localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);
		}
	};

	return (
		<div className="auth-page flex-center">
			<AuthBackgroundArt />

			<div className="auth-form flex-column">
				<h2 className="m-b-s">Log in</h2>

				<div className="m-b-xs">
					<TextField
						label="E-mail"
						placeholder="e.g. name@domain.com"
						onChange={(fieldValue) => setEmail(fieldValue)}
					/>
				</div>

				<div className="m-b-xs">
					<PasswordField
						label="Password"
						placeholder="e.g. *******"
						onChange={(fieldValue) => setPassword(fieldValue)}
					/>
				</div>

				<span className="disclaimer">
					If you don't have an account, create one{" "}
					<a href="/register">here</a>. If you forgot your password,
					retrieve it <a href="/forgot-password">here</a>.
				</span>

				<MainCTA
					onClick={handleLoginClick}
					className="primary m-t-s">
					Login
				</MainCTA>
			</div>
		</div>
	);
};

export default Login;

import { Slider } from "react-burgers";
import { slide as Menu } from "react-burger-menu";
import { Outlet } from "react-router";
import { useState, useEffect } from "react";
import { useUserStore } from "../store/globalStore";

import DropdownField from "../components/form/DropdownField";
import FighterCard from "../components/fighter/FighterCard";
import EmptyFighterCard from "../components/fighter/EmptyFighterCard";
import AbstractBackgroundArt from "../components/AbstractBackgroundArt";

export default function AppLayout() {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isMyLineupOpen, setMyLineupOpen] = useState(false);

	const userInfo = useUserStore((state) => state.userInfo);

	const userLineup = useUserStore((state) => state.userLineup);
	const fetchUserLineup = useUserStore((state) => state.fetchUserLineup);
	const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);

	useEffect(() => {
		fetchUserLineup();
		fetchUserInfo();
	}, [fetchUserLineup, fetchUserInfo]);

	const categories = [
		"Flyweight Division",
		"Bantamweight Division",
		"Featherweight Division",
		"Lightweight Division",
		"Welterweight Division",
		"Middleweight Division",
		"Heavyweight Division",
		"Women's Strawweight Division",
		"Women's Flyweight Division",
		"Women's Bantamweight Division",
	];

	const lineupByCategory = categories.map((category) => {
		const fighter = userLineup.find((f) => f.division === category);
		return { category, fighter };
	});

	return (
		<div id="outer-container">
			<AbstractBackgroundArt />

			<Menu
				isOpen={isMenuOpen}
				onClose={() => setMenuOpen(false)}
				className="app-layout-menu"
				pageWrapId="page-wrap"
				outerContainerId="outer-container">
				<a href="/lineup">
					<img
						className="app-menu-logo m-b-m"
						src="/images/logo-red.svg"
						alt=""
					/>
				</a>

				<a
					className="menu-item"
					href="/lineup">
					Lineup
				</a>
				<a
					className="menu-item"
					href="/profile">
					Profile
				</a>
				<a
					className="menu-item"
					href="/leaderboard">
					Leaderboard
				</a>
				<a
					className="menu-item"
					href="/fights">
					Fights
				</a>
				<a
					className="menu-item m-t-m"
					href="/logout">
					Sign out
				</a>

				<div className="bottom-content">
					<div className="social-share-container m-b-s p-h-s">
						<img
							src="/images/icons/social/facebook-white.svg"
							alt=""
						/>

						<img
							src="/images/icons/social/x-white.svg"
							alt=""
						/>

						<img
							src="/images/icons/social/tiktok-white.svg"
							alt=""
						/>

						<img
							src="/images/icons/social/copy-white.svg"
							alt=""
						/>
					</div>

					<div className="p-h-s">
						<DropdownField white>
							<option>English</option>
							<option>Srpski</option>
						</DropdownField>
					</div>
				</div>
			</Menu>

			<Menu
				right
				isOpen={isMyLineupOpen}
				onClose={() => setMyLineupOpen(false)}
				className="my-lineup-menu"
				pageWrapId="page-wrap"
				outerContainerId="outer-container">
				{lineupByCategory.map(({ category, fighter }, index) => (
					<div
						key={index}
						className="m-b-s">
						{fighter ? (
							<FighterCard
								name={fighter.name}
								division={fighter.division}
								image={`/images/fighter-2.webp`}
							/>
						) : (
							<EmptyFighterCard division={category} />
						)}
					</div>
				))}
			</Menu>

			<header className="app-layout-header">
				<div className="burger-button-wrapper">
					<Slider
						width={26}
						lineHeight={2}
						lineSpacing={5}
						borderRadius={1}
						active={isMenuOpen}
						onClick={() => setMenuOpen(!isMenuOpen)}
					/>
				</div>
				<div className="header-logo-container">
					<div className="header-logo-skew"></div>

					<a href="/lineup">
						<img
							src="/images/logo-white.svg"
							alt=""
						/>
					</a>
				</div>

				<a
					className="header-player-stats"
					href="/profile">
					<span>
						{userInfo?.currentBalance?.toLocaleString() || "0"}{" "}
						<span className="stat-icon">$</span>
					</span>
					<span>
						{userInfo?.points?.toLocaleString() || "0"}{" "}
						<span className="stat-icon">▲</span>
					</span>
				</a>
			</header>

			<main
				id="page-wrap"
				className="app-layout-main">
				<Outlet />
			</main>

			<button
				className="my-lineup-button"
				onClick={() => setMyLineupOpen(!isMyLineupOpen)}>
				<span>My lineup</span>
				<img
					src={`/images/icons/${
						isMyLineupOpen ? "close" : "octagon"
					}.svg`}
					alt=""
				/>
			</button>
		</div>
	);
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toast";

import AppLayout from "./layouts/AppLayout";
import PublicLayout from "./layouts/PublicLayout";

import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Register from "./pages/auth/Register";
import SubmitNewPassword from "./pages/auth/SubmitNewPassword";
import CheckoutPage from "./pages/CheckoutPage";
import Fights from "./pages/Fights";
import Leaderboard from "./pages/Leaderboard";
import Lineup from "./pages/Lineup";
import Message from "./pages/Message";
import Profile from "./pages/Profile";
import PublicProfile from "./pages/PublicProfile";
import ProtectedRoute from "./ProtectedRoute";
import axios from "axios";
import config from "./config";
import Prompt from "./components/Prompt";
import { isTokenExpired } from "./utils/validation";

const userToken = localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY);

if (userToken && !isTokenExpired(userToken)) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
} else {
	localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);
}

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<Login />}
				/>
				<Route
					path="/login"
					element={<Login />}
				/>
				<Route
					path="/logout"
					element={<Logout />}
				/>
				<Route
					path="/register"
					element={<Register />}
				/>
				<Route
					path="/forgot-password"
					element={<ForgotPassword />}
				/>
				<Route
					path="/submit-new-password/:token"
					element={<SubmitNewPassword />}
				/>
				<Route
					path="/account/reset/finish"
					element={
						<Message
							title="Successfull"
							message="Your password has been reset. Click the button below to proceed to your homepage."
						/>
					}
				/>
				<Route
					path="/account/validation-successfull"
					element={
						<Message
							title="Account activated"
							message="Your account has been activated! Click the button below to proceed to your homepage."
						/>
					}
				/>

				<Route 
					path="/account/error" 
					element={
						<Message 
							title="Error" 
							message="An error occurred during your request. Click the button below to go back."
						/>
					}
				/>

				<Route
					element={
						<ProtectedRoute>
							<AppLayout />
						</ProtectedRoute>
					}>
					<Route
						path="/lineup"
						element={<Lineup />}
					/>
					<Route
						path="/leaderboard"
						element={<Leaderboard />}
					/>
					<Route
						path="/fights"
						element={<Fights />}
					/>
					<Route
						path="/profile/:username"
						element={<PublicProfile />}
					/>
					<Route
						path="/profile"
						element={<Profile />}
					/>
				</Route>

				<Route
					element={
						<ProtectedRoute>
							<AppLayout />
						</ProtectedRoute>
					}>
					<Route
						path="/checkout"
						element={<CheckoutPage />}
					/>
				</Route>

				<Route element={<PublicLayout />}>
					<Route
						path="/terms-and-conditions"
						element={<h1>T&C page</h1>}
					/>
				</Route>
			</Routes>

			<Prompt />

			<ToastContainer
				position="bottom-right"
				delay={5000}
			/>
		</BrowserRouter>
	);
};

export default App;

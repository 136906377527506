import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCTA from "../../components/form/MainCTA";
import TextField from "../../components/form/TextField";
import { useUserStore } from "../../store/globalStore.js";
import { error, success } from "../../utils/toast.js";
import AuthBackgroundArt from "../../components/AuthBackgroundArt.js";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const navigate = useNavigate();
	const passwordReset = useUserStore((state) => state.passwordReset);

	const handleResetClick = async () => {
		try {
			await passwordReset({ email });
			navigate("/login");
			success("Password reset email sent.");
		} catch (err) {}
	};

	return (
		<div className="auth-page flex-center">
			<AuthBackgroundArt />

			<div className="auth-form flex-column">
				<h2 className="m-b-s">Reset password</h2>

				<div className="m-b-xs">
					<TextField
						label="E-mail"
						placeholder="e.g. yourvalidemail@domain.com"
						onChange={(fieldValue) => setEmail(fieldValue)}
					/>
				</div>

				<span className="disclaimer">
					Reset link will be sent to your e-mail address.
				</span>

				<MainCTA
					onClick={handleResetClick}
					className="primary m-t-s">
					Request reset
				</MainCTA>
			</div>
		</div>
	);
};

export default ForgotPassword;

import { useState } from "react";
import NumberField from "../NumberField.js";

const BuyTokensForm = () => {
	const [numberOfTokens, setNumberOfTokens] = useState(1);

	return (
		<div>
			<p className="m-b-s">
				You can get more tokens buy paying the listed amount to the
				account bellow.
			</p>

			<div className="ips-modal-form-container">
				<div className="m-b-xs">
					<NumberField
						label="Number of tokens"
						mandatory
						value={numberOfTokens}
						min={0}
						step={1}
						onChange={setNumberOfTokens}
					/>
				</div>
			</div>
		</div>
	);
};

export default BuyTokensForm;
